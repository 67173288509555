

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');


html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media screen and  (min-width: 1200px) {
  .MuiContainer-root {
    max-width: 1300px !important;
  }
}

.MuiTextField-root {
  background: white;
}

.MuiFormHelperText-root {
  color: red;
  background: #ff9e9e;
  padding: 1px 4px;
  margin: 0 !important;
  border-radius: 0px 0px 10px 5px;
  margin-top: -3px !important;
  z-index: 0;
}

/* hard coded fix */
[data-popper-placement] {
  z-index: 1;
}

h4, h5 {
  font-family: Open sans,sans-serif;
  font-weight: 400 !important;
  color: #2058E8e3;
}

.MuiDrawer-paper {
  border-left: 2px solid #1565c0;
  background: #f4f4f4 !important;
}

.cancel-btn {
  background: #dbdbdb !important;
  color: #727272 !important;
  border-color: #727272 !important;
}

.MuiButton-root {
  box-shadow: none !important;
}

.hidden {
  display: none !important;
}

.MuiAccordionSummary-content {
  display: flex !important;
  align-items: 'center' !important;
}

.MuiAccordionSummary-contentGutters {
  display: flex !important;
  align-items: center !important;
}

.align-center {
  display: flex !important;
  align-items: center !important;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#notifications-menu ul {
  padding: 0;
  padding-top: 8px;
}

.mouse-hover:hover {
  cursor:pointer;
  text-decoration: underline;
}

.invisible {
  visibility: hidden !important;
}



@media screen and (max-width: 660px) {
  .MuiTabs-flexContainer {
    max-width: 100%;
    overflow: scroll;
  }
}
